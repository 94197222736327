<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import DesktopNav from '@/components/common/header/DesktopNav.vue';
import UserBalance from '@/components/common/header/UserBalance.vue';
import { getRootDomain } from '@/utils/helpers';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { CLAIM_REWARD } from '@/constants/locales';
import logo from '@/assets/logo-light.svg';
import { useMainHeaderActions } from '@/components/common/header/composables';
import { TEST_CLAIM_REWARD_MODAL } from '@/constants/dataTestAttrs';

const { openRewardsModal } = useMainHeaderActions();

const homepageUrl = computed(() => {
	const rootDomain = getRootDomain();
	if (import.meta.env.MODE === 'development') {
		return `http://${rootDomain}:3001/`;
	} else {
		return `https://${rootDomain}`;
	}
});

const { isAuthorized, isTopNavVisible } = storeToRefs(useAuthStore());
</script>

<template>
	<header
		class="app-header"
		:class="{
			'hide-on-tablet': !isTopNavVisible,
		}"
	>
		<div class="app-header-logo">
			<div class="app-logo">
				<component
					:is="isAuthorized ? 'router-link' : 'a'"
					:to="{ name: 'home' }"
					:href="homepageUrl"
				>
					<img :src="logo" alt="5 Surveys logo" />
				</component>
			</div>
		</div>

		<DesktopNav v-if="isAuthorized" class="app-header-menu" />

		<div v-if="isAuthorized" class="app-header-services">
			<UserBalance />
			<PrimeButton
				class="app-header-reward-button"
				variant="secondary"
				rounded="rounded"
				:label="$t(CLAIM_REWARD)"
				:data-test="TEST_CLAIM_REWARD_MODAL"
				@click="openRewardsModal"
			/>
		</div>
	</header>
</template>

<style scoped lang="scss">
@use '@/styles/mixins.scss' as *;

.app-header {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	padding: calc(0.75rem + env(safe-area-inset-top)) 0 0.75rem;
	width: 100%;
	max-width: 90rem;
	background-color: var(--5s-body-background);

	&:before {
		@include breakpoint(desktop) {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			right: 0;
			border-bottom: 1px solid var(--5s-dashboard-border);
			width: 100vw;
			transform: translateX(-50%);
		}
	}

	&.hide-on-tablet {
		display: none;

		@include breakpoint(desktop) {
			display: flex;
		}
	}
}

.app-header-logo {
	display: none;

	@include breakpoint(desktop) {
		display: block;
	}

	a {
		display: inline-block;
		vertical-align: middle;
		width: 9.875rem;
		height: 2.75rem;
		max-width: 100%;
	}

	svg {
		width: 100%;
		height: auto;
	}
}

.app-header-services {
	display: flex;
	align-items: center;
	gap: 1.375rem;
	justify-content: space-between;
	width: 100%;

	@include breakpoint(desktop) {
		justify-content: flex-end;
		width: auto;
	}
}

.app-header-reward-button {
	&.p-btn {
		font-size: var(--p-font-12);
	}
}
</style>
