import { storeToRefs } from 'pinia';
import { InAppReview } from '@capacitor-community/in-app-review';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { randomizeChance } from '@/utils/helpers';
import { useUserStore } from '@/stores/user';

export const useAppReviewPrompt = () => {
	const { appReviewProbability } = storeToRefs(useUserStore());
	const { isMobileApp } = useMobileApp();

	const showInAppReviewPrompt = async () => {
		if (!isMobileApp || !randomizeChance(appReviewProbability.value)) return;

		try {
			await InAppReview.requestReview();
		} catch (error) {
			console.error('Error requesting in-app review:', error);
		}
	};

	return { showInAppReviewPrompt };
};
