export const SIGN_IN = 'sign_in';
export const SAVE = 'save';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const RESET_PASSWORD = 'reset_password';
export const CONFIRM_EMAIL = 'confirm_email';
export const CONFIRM_EMAIL_MESSAGE = 'confirm_email_message';
export const RESEND = 'resend';
export const EMAIL_REQUIRED = 'email_required';
export const EMAIL_NOT_VALID = 'email_not_valid';
export const PASSWORD_REQUIRED = 'password_required';
export const PASSWORD_MIN_LENGTH = 'password_min_length';
export const COINS = 'coins';
export const SELECT = 'select';
export const SUCCESS = 'success';
export const BALANCE = 'balance';
export const CONGRATS = 'congrats';
export const YOU_CAN_CLAIM = 'you_can_claim';
export const REWARD_SENT = 'reward_sent';
export const ENTER_NEW_PASSWORD = 'enter_new_password';
export const CONFIRM_PASSWORD = 'confirm_password';
export const CONFIRM = 'confirm';
export const CONFIRM_PASSWORD_REQUIRED = 'confirm_password_required';
export const UPDATED_BALANCE = 'updated_balance';
export const SOMETHING_WENT_WRONG = 'something_went_wrong';
export const TRY_AGAIN = 'try_again';
export const TRANSACTIONS = 'transactions';
export const TRANSACTIONS_EMPTY_TITLE = 'transactions_empty_title';
export const TRANSACTIONS_EMPTY_DESCRIPTION = 'transactions_empty_description';
export const SELECT_REWARDS = 'select_rewards';
export const SEARCH_REWARDS = 'search_rewards';
export const NO_REWARDS_TITLE = 'no_rewards_title';
export const NO_REWARDS_MESSAGE = 'no_rewards_message';
export const CLAIM_REWARD = 'claim_reward';
export const RECEIVE_REWARD_VIA_EMAIL = 'receive_reward_via_email';
export const TRANSFER = 'transfer';
export const PREPAID_CARDS = 'prepaid_cards';
export const GIFT_CARDS = 'gift_cards';
export const CHARITY = 'charity';
export const VIEW_MORE_REWARDS = 'view_more_rewards';
export const NO_REWARDS_WITH_NAME = 'no_rewards_with_name';
export const SURVEYS = 'surveys';
export const REWARDS = 'rewards';
export const REWARD = 'reward';
export const DELETE = 'delete';
export const DELETE_ACCOUNT = 'delete_account';
export const DELETE_ACCOUNT_QUESTION = 'delete_account_question';
export const DELETE_ACCOUNT_INFORMATION = 'delete_account_information';
export const NO_REWARDS_HISTORY_MESSAGE = 'no_rewards_history_message';
export const DONE = 'done';
export const AMOUNT = 'amount';
export const DATE = 'date';
export const CHANGE_TIMEZONE = 'change_timezone';
export const CHANGE_EMAIL = 'change_email';
export const NEW_EMAIL = 'new_email';
export const CHANGE_PASSWORD = 'change_password';
export const OLD_PASSWORD = 'old_password';
export const LOGOUT = 'logout';
export const PROFILE_SURVEYS_COMPLETED = 'profile_surveys_completed';
export const HELP = 'help';
export const NO_MORE_ENTRIES = 'no_more_entries';
export const LOADING_MORE = 'loading_more';
export const OTHER_TRANSACTION = 'other_transaction';
export const EMAIL_PREFERENCES = 'email_preferences';
export const EMAIL_SUBS_TITLE = 'email_subs_title';
export const EMAIL_SUBS_SHOW_ALL = 'email_subs_show_all';
export const EMAIL_SUB_OFFERS = 'email_sub_offers';
export const EMAIL_SUB_REMINDERS = 'email_sub_reminders';
export const EMAIL_SUB_WEEKLY_REPORT = 'email_sub_weekly_report';
export const EMAIL_SUB_ACCOUNT_UPDATES = 'email_sub_account_updates';
export const EMAIL_SUB_SURVEY_INVITATIONS = 'email_sub_survey_invitations';
export const EMAIL_SUB_OFFERS_DESCRIPTION = 'email_sub_offers_description';
export const EMAIL_SUB_REMINDERS_DESCRIPTION =
	'email_sub_reminders_description';
export const EMAIL_SUB_WEEKLY_REPORT_DESCRIPTION =
	'email_sub_weekly_report_description_undated';
export const EMAIL_SUB_ACCOUNT_UPDATES_DESCRIPTION =
	'email_sub_account_updates_description_undated';
export const EMAIL_SUB_SURVEY_INVITATIONS_DESCRIPTION =
	'email_sub_survey_invitations_description';
export const EMAIL_SUBS_UPDATED = 'email_subs_updated';
export const EMAIL_SUBS_NOT_UPDATED = 'email_subs_not_updated';
export const LANGUAGE = 'language';
export const CHANGE_LANGUAGE_TITLE = 'change_language_title';
export const CHANGE_LANGUAGE_SUBTITLE = 'change_language_subtitle';
export const CHANGE_LANGUAGE_MESSAGE = 'change_language_message_fix';
export const ACCOUNT = 'account';
export const MORE = 'more';
export const UNLINK_SOCIALS = 'unlink_socials';
export const RESET_PASSWORD_SENT_MESSAGE = 'reset_password_sent_message';
export const RESET_PASSWORD_SENT_DETAILS =
	'reset_password_sent_message-details';
export const RESET_PASSWORD_SENT_SPAM = 'reset_password_sent_message-spam';
export const SEARCH_COUNTRY = 'search_country';
export const LOCALIZATION_EMPTY_SEARCH = 'localization_modal_no_items';
export const OTHER_OPTIONS = 'other_options';
export const CONTINUE = 'continue';
export const LOGIN = 'login';
export const SIGN_UP = 'sign_up';
export const SIGN_UP_INFO = 'sign_up_info';
export const EMAIL_VERIFY_WARNING = 'email_verification_warning';
export const SENT = 'sent';
export const CONTINUE_WITH_SOCIAL = 'continue_with_provider';
export const SOCIAL_ACCOUNT_MESSAGE = 'social_account_message';
export const NOT_YOUR = 'not_your';
export const USE_ANOTHER_ACCOUNT = 'use_another_account';
export const SIGN_UP_SOCIAL: Record<string, string> = {
	google: 'sign_up_google',
	facebook: 'sign_up_facebook',
	apple: 'sign_up_apple',
};
export const ACCOUNT_EXISTS = 'account_exists';
export const LINK_ACCOUNTS_MESSAGE = 'link_account_message';
export const SET_PASSWORD = 'set_password';
export const SET_PASSWORD_MESSAGE = 'set_password_message';
export const CLAIM_EMAIL = 'claim_email';
export const PROCESSING = 'processing';
export const NO_CONNECTION_TITLE = 'no_connection_title';
export const NO_CONNECTION_MESSAGE = 'no_connection_message';
export const RELOAD = 'reload';
export const CODE_REQUIRED = 'code_required';
export const CODE_SENT = 'code_sent';
export const CODE_SENT_MESSAGE = 'code_sent_message';
export const CODE_SENT_QUESTION = 'code_sent_question';
export const RESEND_CODE = 'resend_code';
export const NOT_VERIFIED_CODE = 'not_verified_code';
export const CONFIRM_PAYPAL_EMAIL = 'confirm_paypal_email';
export const CONFIRM_PAYPAL_MESSAGE = 'confirm_paypal_message';
export const PROCEED = 'proceed';
export const PASSWORD_NOT_EQUAL = 'password_not_equal';
export const REFUNDED = 'refunded';
export const ERROR = 'error';
export const REFUND = 'refund';
export const SELECT_LANGUAGE = 'select_language';
export const UPDATE_DIALOG_TITLE = 'update_dialog_title';
export const UPDATE_DIALOG_MESSAGE = 'update_dialog_message';
export const EMAIL_TO_PREVIOUS = 'email_to_previous';
export const CONFIRM_CLAIM_MODAL_TITLE = 'confirm_claim_modal_title';
export const CONFIRM_CLAIM_MODAL_MESSAGE = 'confirm_claim_modal_message';
export const CONFIRM_VIA_EMAIL = 'confirm_via_email';
export const LOGIN_TITLE = 'login_title_3';
export const LOGIN_SUB_TITLE = 'login_sub_title_2';
export const LOGIN_SIGNUP = 'login_signup';
export const OR = 'or';
export const ACCEPT_5S_LEGAL = 'accept_fs_legal';
export const SELECT_COUNTRY_LANGUAGE_HEADER = 'select_country_language_header';
export const LANGUAGE_COUNTRY = 'language_country';
export const NEED_NEW_PASSWORD = 'need_new_password';
export const CURRENT_BALANCE = 'current_balance';
export const FIVE_SURVEYS_REWARD = 'five_surveys_reward';
export const CASH_EARNED = 'cash_earned';
export const SETTINGS = 'settings';
export const TIMEZONE = 'timezone';
export const YOUR_PASSWORD = 'your_password';
export const SAVE_CHANGES = 'save_changes';
export const COMPLETED_5_SURVEYS_MODAL_TITLE =
	'completed_5_surveys_modal_title';
export const COMPLETED_5_SURVEYS_MODAL_SUBTITLE =
	'completed_5_surveys_modal_subtitle';
export const COMPLETED_5_SURVEYS_MODAL_MESSAGE =
	'completed_5_surveys_modal_message';
export const REWARD_SENT_MODAL_HEADER = 'reward_sent_modal_header';
export const REWARD_SENT_MODAL_MESSAGE = 'reward_sent_modal_message';
export const GREAT = 'great';
export const FIVE_SURVEYS_NO_REWARDS_TITLE = 'five_surveys_no_rewards_title';
export const TRANSACTIONS_REWARDS = 'transactions_rewards';
export const REFRESH = 'refresh';
export const LOGOUT_QUESTION = 'logout_question';
export const UPDATED_PROGRESS = 'updated_progress';
export const GREAT_JOB = 'great_job';
export const FIRST_COMPLETE = 'first_complete';
export const COMPLETE_MORE = 'complete_more_updated';
export const TOTAL_CLAIMS = 'total_claims';
export const HALF_PROGRESS_MODAL_HEADER = 'half_progress_modal_header';
export const HALF_PROGRESS_MODAL_TITLE = 'half_progress_modal_title';
export const HALF_PROGRESS_MODAL_DESCRIPTION_1 =
	'half_progress_modal_description_1';
export const HALF_PROGRESS_MODAL_DESCRIPTION_2 =
	'half_progress_modal_description_2';
export const HALF_PROGRESS_MODAL_MESSAGE = 'half_progress_modal_message';
export const FULL_NAME = 'full_name';
export const TAG = 'tag';
export const FULL_NAME_REQUIRED = 'full_name_required';
export const TAG_REQUIRED = 'tag_required';
export const FULL_NAME_MIN_LENGTH = 'full_name_min_length';
export const FULL_NAME_MAX_LENGTH = 'full_name_max_length';
export const TAG_MIN_LENGTH = 'tag_min_length';
export const TAG_MAX_LENGTH = 'tag_max_length';
export const REVOLUT_CLAIM_DATA = 'revolut_claim_data';
export const SHOW_HALF_SURVEYS = 'show_half_surveys';
export const LEARN_MORE = 'learn_more';
export const WHAT_IS_HALF_SURVEYS = 'what_is_half_surveys';
export const HALF_SURVEYS_DESCRIPTION_ICON = 'half_surveys_icon_description';
export const HALF_SURVEYS_INFO_MESSAGE_1 = 'half_surveys_info_message_1';
export const HALF_SURVEYS_INFO_MESSAGE_2 = 'half_surveys_info_message_2';
export const HALF_SURVEYS_INFO_MESSAGE_3 = 'half_surveys_info_message_3';
export const REFERRALS = 'referrals';
export const REFERRAL = 'referral';
export const HOW_IT_WORKS = 'how_it_works';
export const REF_STEP_1_TITLE = 'ref_step_1_title';
export const REF_STEP_1_DESCRIPTION = 'ref_step_1_description';
export const REF_STEP_2_TITLE = 'ref_step_2_title';
export const REF_STEP_2_DESCRIPTION = 'ref_step_2_description';
export const REF_STEP_3_TITLE = 'ref_step_3_title';
export const REF_STEP_3_DESCRIPTION = 'ref_step_3_description';
export const REF_STATS_EARNED = 'ref_stats_earned';
export const REF_STATS_FRIENDS = 'ref_stats_friends';
export const REF_COPY_LINK = 'ref_copy_link';
export const REF_COPY_WARNING = 'ref_copy_warning';
export const REF_LINK_COPIED = 'ref_link_copied';
export const ASK_PERMISSIONS_MODAL_HEADER = 'ask_permissions_modal_header';
export const ASK_PERMISSIONS_MODAL_TITLE = 'ask_permissions_modal_title';
export const ASK_PERMISSIONS_MODAL_MESSAGE = 'ask_permissions_modal_message';
export const ASK_PERMISSIONS_MODAL_IMPORTANT =
	'ask_permissions_modal_important';
export const SELECT_CHOICE = 'select_choice';
export const REF_NOTIFICATION_MODAL_HEADER = 'ref_notification_modal_header';
export const REF_NOTIFICATION_MODAL_TITLE = 'ref_notification_modal_title';
export const REF_NOTIFICATION_MODAL_MESSAGE = 'ref_notification_modal_message';
export const REF_NOTIFICATION_MODAL_FOOTER = 'ref_notification_modal_footer';
export const NEW_VERSION_AVAILABLE_HEADER = 'new_version_available_header';
export const NEW_VERSION_AVAILABLE_TITLE = 'new_version_available_title';
export const NEW_VERSION_AVAILABLE_MSG = 'new_version_available_msg_store';
export const NEW_VERSION_AVAILABLE_BTN = 'new_version_available_btn';
export const NEW_VERSION_AVAILABLE_SUCCESS = 'new_version_available_success';
export const NEW_VERSION_AVAILABLE_TITLE_DOWNLOADING =
	'new_version_available_title_downloading';
export const NEW_VERSION_AVAILABLE_MSG_DOWNLOADING =
	'new_version_available_msg_downloading';
export const NEW_VERSION_AVAILABLE_TITLE_INSTALLING =
	'new_version_available_title_installing';
export const NEW_VERSION_AVAILABLE_MSG_INSTALLING =
	'new_version_available_msg_installing';
export const NEW_VERSION_AVAILABLE_TITLE_INSTALLED =
	'new_version_available_title_installed';
export const NEW_VERSION_AVAILABLE_MSG_INSTALLED =
	'new_version_available_msg_installed';
export const NEW_VERSION_AVAILABLE_BTN_INSTALLED =
	'new_version_available_btn_installed';
export const NEW_VERSION_AVAILABLE_TITLE_FAILED =
	'new_version_available_title_failed';
export const NEW_VERSION_AVAILABLE_MSG_FAILED =
	'new_version_available_msg_failed';
export const NEW_VERSION_AVAILABLE_BTN_FAILED =
	'new_version_available_btn_failed';
export const SEARCH = 'search';
export const GAMES = 'games';
export const WELL_DONE = 'well_done';
export const OFFER_COMPLETED_TITLE = 'offer_completed_title';
export const OFFER_COMPLETED_MESSAGE = 'offer_completed_message';
export const PENDING = 'pending';
export const NO_PENDING = 'no_pending';
export const YOU_DO_NOT_HAVE_PENDING = 'you_do_not_have_pending';
export const EARN_MONEY = 'earn_money';
export const TOTAL_PENDINGS_TITLE = 'total_pendings_title';
export const TOTAL_PENDINGS_TEXT = 'total_pendings_text';
export const PENDING_UNTIL = 'pending_until';
export const YOU_EARNED = 'you_earned';
export const WE_CREDITED_YOU = 'we_credited_you';
export const OKAY = 'okay';
export const CANCEL = 'cancel';
export const CLOSE = 'close';
export const CLOSE_CONFIRM = 'close_confirm';
export const GAME = 'game';
export const LESS_THEN_MINUTE = 'less_than_minute';
export const EXPIRED = 'expired';
export const DOUBLE_PAYOUT_EXPIRE_IN = 'double_payout_expire_in';
export const CHALLENGE_COMPLETED = 'challenge_completed';
export const OFFERS = 'offers';
export const REFER_FRIEND = 'refer_friend';
export const OFFERS_ACTIVITIES = 'offers_activities';
export const OFFER = 'offer';
export const NO_RESULT_FOUNDED = 'no_result_founded';
