import type { StatusContent } from '@/types';
import {
	OTHER_TRANSACTION,
	REFERRAL,
	REFUND,
	REWARD,
	SUCCESS,
	GAME,
	OFFER,
} from '@/constants/locales';
import {
	PiBlock,
	PiCheckCircle,
	PiGiftSimple,
	PiUsersGroupAdd,
	PiWarningCircle,
	PiGameController,
	Pi2X,
	PiCrown,
} from '@primeinsightsgroupllc/prime-icons';

enum Transactions {
	SUCCESS,
	REWARD,
	REFUND,
	OTHER_TRANSACTION,
	REFFERAL,
	GAME,
	DOUBLE_REWARD,
	OFFER
}
// !TODO TRANSACTIONS TYPES AND CONTENT SHOULD BE REWORKED WHEN API WILL BE READY
const transactionsContent: Record<number, StatusContent> = {
	[Transactions.SUCCESS]: {
		text: SUCCESS,
		icon: PiCheckCircle,
		variant: 'primary',
	},
	[Transactions.REWARD]: {
		text: REWARD,
		icon: PiGiftSimple,
		variant: 'tertiary',
	},
	[Transactions.REFUND]: {
		text: REFUND,
		icon: PiWarningCircle,
		variant: 'secondary',
	},
	[Transactions.OTHER_TRANSACTION]: {
		text: OTHER_TRANSACTION,
		icon: PiBlock,
		variant: 'error',
	},
	[Transactions.REFFERAL]: {
		text: REFERRAL,
		icon: PiUsersGroupAdd,
		variant: 'referral',
	},
	[Transactions.GAME]: {
		text: GAME,
		icon: PiGameController,
		variant: 'primary',
	},
	[Transactions.DOUBLE_REWARD]: {
		text: SUCCESS,
		icon: Pi2X,
		variant: 'primary',
	},
	[Transactions.OFFER]: {
		text: OFFER,
		icon: PiCrown,
		variant: 'primary',
	},
};

export const transactionContent: Record<number, StatusContent> = {
	1: transactionsContent[Transactions.SUCCESS],
	3: transactionsContent[Transactions.SUCCESS],
	7: transactionsContent[Transactions.OFFER],
	9: transactionsContent[Transactions.DOUBLE_REWARD],
	100: transactionsContent[Transactions.REWARD],
	110: transactionsContent[Transactions.REFUND],
	115: transactionsContent[Transactions.REFUND],
	265: transactionsContent[Transactions.REFFERAL],
	600: transactionsContent[Transactions.SUCCESS],
	610: transactionsContent[Transactions.GAME],
};

export const getTransactionContent = (type: number): StatusContent => {
	return (
		transactionContent[type] ||
		transactionsContent[Transactions.OTHER_TRANSACTION]
	);
};

// These transactions will not be display congratulations modals when balance-updated ws event received
export const silentTransactions = [100, 600, 610, 7];

export const transactionsWithModalFooter = [1, 9];

export const transactionsUpdateProgress = [1];
