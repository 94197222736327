<script setup lang="ts">
import {
	ref,
	computed,
	watch,
	onMounted,
	onBeforeUnmount,
	useTemplateRef,
} from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const topAreaHeight = ref(0);
const topAreaEl = useTemplateRef('topAreaEl');

const contentPaddingTop = computed(() =>
	topAreaHeight.value
		? route.meta.authRoute
			? `calc(${topAreaHeight.value}px + env(safe-area-inset-top))`
			: `${topAreaHeight.value}px`
		: 'env(safe-area-inset-top)'
);

const calculateTopAreaHeight = () => {
	if (topAreaEl.value) {
		topAreaHeight.value = topAreaEl.value.clientHeight;
	}
};

onMounted(() => {
	calculateTopAreaHeight();

	window.addEventListener('resize', calculateTopAreaHeight);
});

onBeforeUnmount(() => {
	window.removeEventListener('resize', calculateTopAreaHeight);
});

watch(
	topAreaEl,
	(newVal) => {
		if (newVal) {
			resizeObserver.observe(newVal);
		}
	},
	{ immediate: true, deep: true }
);

const resizeObserver = new ResizeObserver(() => {
	calculateTopAreaHeight();
});

onBeforeUnmount(() => {
	resizeObserver.disconnect();
});
</script>

<template>
	<div class="app-wrapper">
		<div ref="topAreaEl" class="app-fixed-top">
			<slot name="appTopArea" />
			<div class="top-divider" />
		</div>
		<div class="app-content">
			<slot name="appMainContent" />
		</div>
		<div v-if="$slots.appBottomArea" class="app-bottom">
			<slot name="appBottomArea" />
		</div>
	</div>
</template>

<style scoped lang="scss">
@use '@/styles/mixins' as *;

.app-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	padding-top: v-bind(contentPaddingTop);
	padding-bottom: env(safe-area-inset-bottom);
	width: 100%;
	background-color: inherit;
}

.app-fixed-top {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	width: 100%;
	z-index: 20;
	background-color: var(--5s-body-background);
	@include breakpoint(mobile) {
		padding-left: 2.75rem;
		padding-right: 2.75rem;

		.login-page & {
			padding: 0 2.25rem;
		}
	}

	.login-page & {
		position: absolute;
	}
}

.top-divider {
	height: 1.5rem;
	width: 100%;
	left: 0;
	background-color: var(--5s-body-background);

	.login-page & {
		display: none;
	}

	&:before {
		content: '';
		width: 100%;
		height: 1.5rem;
		left: 0;
		position: absolute;
		border-width: 0;
		background-color: var(--5s-page-background);
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
	}
}

.app-content {
	position: relative;
	display: flex;
	justify-content: center;
	flex: 1 0 auto;
	width: 100%;
	padding: 0 0.75rem 6rem;
	background-color: var(--5s-page-background);

	.login-page & {
		padding: 0;
	}

	@include breakpoint(mobile) {
		padding: 0 2.25rem 2.75rem;
	}

	&:last-child {
		padding-bottom: 0;
	}
}
</style>
