import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useMessagesStore } from '@/stores/messages';
import { useModalStorage } from '@primeinsightsgroupllc-modals/prime-modals';
import { useAuthStore } from '@/stores/auth';
import { MessageChannels } from '@/enums';
import type { Notification, ChannelType } from '@/types';
import {
	HALF_PROGRESS_NOTIFICATION_MODAL,
	OFFER_REWARD_COMPLETE_MODAL,
	OFFER_REWARD_DELAY_MODAL,
	REFERRALS_NOTIFICATION_MODAL,
} from '@/constants/modals';
import { useAppReviewPrompt } from '@/utils/composables/useInAppReview';

export const useAppNotificationListener = (): void => {
	const { createNewModal } = useModalStorage();
	const { notificationsList } = storeToRefs(useMessagesStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const messagesStore = useMessagesStore();
	const { showInAppReviewPrompt } = useAppReviewPrompt();

	const showNotificationsModal = async ({ data, id }: Notification) => {
		const requiredOptions = {
			id,
			channel: MessageChannels.NOTIFICATION as ChannelType,
			shared: true,
			onClose: () => messagesStore.readNotification(id),
		};
		switch (data.event_name) {
			case 'one_time_intro_to_half_surveys':
				createNewModal(HALF_PROGRESS_NOTIFICATION_MODAL, {
					...requiredOptions,
				});
				break;
			case 'introduce_to_referrals':
				createNewModal(REFERRALS_NOTIFICATION_MODAL, {
					...requiredOptions,
				});
				break;
			case 'offerwall_transaction_delayed':
				createNewModal(OFFER_REWARD_DELAY_MODAL, {
					...requiredOptions,
					...data,
				});
				break;
			case 'offerwall_transaction_completed':
				createNewModal(OFFER_REWARD_COMPLETE_MODAL, {
					...requiredOptions,
					...data,
				});

				await showInAppReviewPrompt();

				break;
			default:
				break;
		}
	};

	watch(
		[userAuthToken, notificationsList],
		async (newValue) => {
			const [token, notifications] = newValue;
			if (token && notifications?.length) {
				notifications.forEach((notification) => {
					showNotificationsModal(notification);
				});
			}
		},
		{ immediate: true }
	);
};
